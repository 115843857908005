import React, {
  useState,
  useEffect,
  useContext,
  FC,
  useCallback,
  useRef,
} from "react";
import { useLocation } from "react-router";
import { AppStore } from "../app/store";
import routes from "../app/routes";
import { HomeIcon } from "./SvgIcons";
import { AnalyticsActionType } from "../app/contexts/Analytics/types";
import {
  getPageNameFromPathName,
  useShouldSendPageView,
} from "../app/contexts/Analytics/helpers";
import {
  ReferrerContext,
  initialReferrerContextState,
} from "../app/contexts/ReferrerContext/context";
import URI from "urijs";
import { parseSearchParams, useCurrentPlanYear } from "../helpers";
import { routesWithQueryParamPages } from "../app/contexts/Analytics/constants";
import { ArrowIcon } from "@cmsgov/ds-medicare-gov";
import {
  ReferrerContextValueKey,
  ReferrerContextValues,
  RouteReferrerIconKey,
} from "../app/contexts/ReferrerContext/types";

/**
 * Map back link icon keys to icons
 * The default icon (`BACK`) is imported directly into `ActionsBar`
 */
export const routeReferrerIconMappings: Record<
  RouteReferrerIconKey,
  JSX.Element
> = {
  [RouteReferrerIconKey.Back]: <ArrowIcon direction="left" />,
  [RouteReferrerIconKey.Home]: <HomeIcon />,
};

/**
 * The routes added to the context mapping
 */
export interface ReferrerRoutes
  extends Pick<
      typeof routes,
      "searchResults" | "comparePlans" | "yearOverYear"
    >,
    Pick<typeof routes.summary, "landingPage"> {}
export type ReferrerRouteValues = ReferrerRoutes[keyof ReferrerRoutes];

/**
 * Defines what text and optional icon (other than `BACK` / `ArrowIcon`) should
 * be used in the `ActionsBar` to link back to the referring route's pathname.
 * The `default` is a fallback option.
 */
export const routeReferrerContextMappings: {
  [key in ReferrerRouteValues]: Partial<ReferrerContextValues>;
} & { default: Partial<ReferrerContextValues> } = {
  [routes.searchResults]: {
    goBackTextId: "app.terms.go_back_to_search_results",
  },
  [routes.comparePlans]: {
    goBackTextId: "app.terms.go_back_to_plan_compare",
  },
  [routes.yearOverYear]: {
    goBackTextId: "app.terms.go_back_to_year_over_year",
  },
  [routes.summary.landingPage]: {
    goBackTextId: "summary_page.title",
    iconKey: RouteReferrerIconKey.Home,
  },
  default: {
    goBackTextId: "app.terms.start_a_new_search",
  },
};

export const getPageName = ({
  pathname,
  queryParamPage,
}: {
  pathname: string;
  queryParamPage?: string | undefined;
}) => {
  const useQueryParamPage =
    routesWithQueryParamPages.includes(pathname) && queryParamPage;
  return `${getPageNameFromPathName(pathname)}${
    useQueryParamPage ? `/${queryParamPage.toLowerCase()}` : ""
  }`;
};

export const ReferrerDetector: FC = ({ children }) => {
  // Context
  const {
    state: { tealiumLoaded },
    dispatch,
  } = useContext(AppStore.AppContext);

  // Hooks
  const location = useLocation();
  const shouldSendPageView = useShouldSendPageView();
  const mctCurrentPlanYear = useCurrentPlanYear();

  // State
  const [previousLocation, setPreviousLocation] = useState("");
  const [previousPage, setPreviousPage] = useState<string | undefined>("");
  const [contextValues, setContextValues] = useState(
    initialReferrerContextState
  );

  // Refs
  const previousLocationRef = useRef<string>("");

  const updateContextValues = useCallback(
    (values: Partial<ReferrerContextValues>) =>
      setContextValues(contextValues => ({ ...contextValues, ...values })),
    []
  );

  useEffect(() => {
    if (!tealiumLoaded) {
      return;
    }
    const { pathname, search } = location;
    const routeShouldUseQueryParamPage =
      routesWithQueryParamPages.includes(pathname);
    if (routeShouldUseQueryParamPage && !search) {
      // Wait until query params are populated to determine what value should
      // be used to record a pageview, based on the value of "page" in query params
      return;
    }
    const uri = new URI(search);
    const { page: queryParamPage } = parseSearchParams(uri.search(true));
    const queryParamPageHasChanged = queryParamPage !== previousPage;
    const pageHasChanged = previousLocation !== pathname;
    let useQueryParamPage = false;

    if (pageHasChanged) {
      previousLocationRef.current = previousLocation;
      setPreviousLocation(pathname);
    }

    if (queryParamPageHasChanged) {
      setPreviousPage(queryParamPage);
      if (queryParamPage && routeShouldUseQueryParamPage) {
        useQueryParamPage = true;
      }
    }

    if (pageHasChanged) {
      if (!useQueryParamPage) {
        updateContextValues({
          ...(routeReferrerContextMappings[previousLocation] ||
            routeReferrerContextMappings.default),
          [ReferrerContextValueKey.PreviousRoute]: previousLocationRef.current,
        });
      }
    }

    if ((pageHasChanged || useQueryParamPage) && shouldSendPageView) {
      dispatch({
        type: AnalyticsActionType.SEND_PAGE_VIEW,
        settings: {
          custom_page_name: queryParamPage,
          page_name: getPageName({ pathname, queryParamPage }),
          location,
          mctCurrentPlanYear,
        },
      });
    }
  }, [
    dispatch,
    location,
    mctCurrentPlanYear,
    previousLocation,
    previousPage,
    shouldSendPageView,
    tealiumLoaded,
    updateContextValues,
  ]);

  return (
    <ReferrerContext.Provider
      value={{
        goBackTextId: contextValues.goBackTextId,
        iconKey: contextValues.iconKey,
        previousRoute: contextValues.previousRoute,
      }}
    >
      {children}
    </ReferrerContext.Provider>
  );
};
