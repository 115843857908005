import {
  ActionType,
  Action,
  Beneficiary,
  Pharmacy,
  LowIncomeSubsidyStatus,
  Plan,
  FullPlanId,
  CoverageInfo,
  OtherBenefit,
  OtherBenefitCategory,
  UserLanguage,
  PacePlan,
  BasePlan,
  ExtendedCoverageInfo,
  OecStatus,
  PlanType,
  PlanBenefitRedactionIssue,
  RedactionType,
  DrugPricingRedactionIssue,
  Redaction,
  SearchResultPlan,
  CoverageType,
  AdditionalSupplementalBenefits,
} from "../@types";
import { parsePlanLongIdIntoUrlPartForApi, makePlanLongId, logError } from ".";
import { getPlanCard, getPlanInfoForNextYear } from "../api";
import { Dispatch } from "react";
import { isMaPlanType } from "./planTypes";
import {
  DrugPricingRedactionIssues,
  PlanBenefitRedactionIssues,
} from "./CONSTANTS";
import { ApiError } from "./errors";

export const fetchCurrentPlan = (
  beneficiary: Beneficiary | undefined,
  pharmacies: Pharmacy[],
  lis: LowIncomeSubsidyStatus,
  dispatch: Dispatch<Action>,
  nextYearPlanIds?: FullPlanId[],
  futureLis?: LowIncomeSubsidyStatus
): Promise<void> => {
  if (!beneficiary || !beneficiary.coverage_current.length) {
    return Promise.resolve();
  }

  const coverage = {
    ...beneficiary.coverage_current[0],
    contract_year: beneficiary.coverage_current[0].coverage_year,
  };

  if (
    !coverage.contract_id ||
    !coverage.contract_year ||
    !coverage.plan_id ||
    !coverage.segment_id
  ) {
    return Promise.resolve();
  }

  const apiUrl = parsePlanLongIdIntoUrlPartForApi(makePlanLongId(coverage));
  const npis = pharmacies.map(p => p.npi);

  if (nextYearPlanIds && nextYearPlanIds[0]) {
    if (nextYearPlanIds.length === 1) {
      const longIdForNextYear = parsePlanLongIdIntoUrlPartForApi(
        makePlanLongId(nextYearPlanIds[0])
      );
      getPlanCard(longIdForNextYear, npis, futureLis || lis)
        .then(plan => {
          dispatch({
            type: ActionType.ADD_NEXT_YEAR_COVERAGE,
            payload: plan,
          });
        })
        .catch(e => {
          logError(
            "Failed to retrieve plan card for next year plan (planHelpers/fetchCurrentPlan)",
            e
          );
        });
    } else if (nextYearPlanIds.length > 1) {
      dispatch({
        type: ActionType.UPDATE_MULTIPLE_ROLLOVER_PLANS,
        payload: true,
      });
    }
  }

  return getPlanCard(apiUrl, npis, lis)
    .then(plan => {
      dispatch({
        type: ActionType.ADD_CURRENT_COVERAGE,
        payload: plan,
      });
    })
    .catch(e => {
      logError(
        "Failed to retrieve plan card (planHelpers/fetchCurrentPlan)",
        e
      );
    });
};

export const shouldSuppressPlanBenefits = (redactions: Redaction[]): boolean =>
  redactions.some(
    redaction =>
      redaction.type === RedactionType.suppression &&
      PlanBenefitRedactionIssues.includes(
        redaction.issue as PlanBenefitRedactionIssue
      )
  );

export const calculateYourResponsibility = (
  healthDeductible: number | string,
  msaYearlyDeposit: number
): number => {
  let deductible = healthDeductible;

  // The annual_deductible property is a string that has text (i.e. "$9,400 annual deductible")
  if (typeof deductible === "string") {
    deductible = deductible.replace(/\D/g, "");
  }

  const result = Number(deductible) - msaYearlyDeposit;

  return result < 0 ? 0 : result;
};

export const shouldSuppressDrugPricing = (redactions: Redaction[]): boolean =>
  redactions.some(
    redaction =>
      redaction.type === RedactionType.suppression &&
      DrugPricingRedactionIssues.includes(
        redaction.issue as DrugPricingRedactionIssue
      )
  );

export const fetchPlanIdsForNextYear = async (
  coverage: CoverageInfo,
  origin: string
): Promise<FullPlanId[] | undefined> => {
  const parsedCoverage = {
    ...coverage,
    contract_year: coverage.coverage_year,
  };

  if (
    !parsedCoverage.contract_id ||
    !parsedCoverage.contract_year ||
    !parsedCoverage.plan_id ||
    !parsedCoverage.segment_id
  ) {
    return undefined;
  }

  try {
    const nextYearPlanIds = await getPlanInfoForNextYear(
      parsePlanLongIdIntoUrlPartForApi(makePlanLongId(parsedCoverage))
    );
    return nextYearPlanIds;
  } catch (e) {
    logError(
      `Could not get plan info for next year (${origin})`,
      e as ApiError
    );
    return undefined;
  }
};

export const getAdditionalBenefit = (
  plan: Plan,
  type: keyof AdditionalSupplementalBenefits,
  category: string,
  benefit: string
): OtherBenefit | null => {
  const cat = `SB_CAT_${category.toUpperCase()}`;
  const bene = `SB_${benefit.toUpperCase()}`;

  if (plan.additional_supplemental_benefits) {
    const otherCategory = plan.additional_supplemental_benefits[type].find(
      (benefit: OtherBenefitCategory) => benefit.category === cat
    );

    if (otherCategory) {
      const otherBenefit = otherCategory.benefits.find(
        (benefit: OtherBenefit) => benefit.benefit === bene
      );

      if (otherBenefit) {
        return otherBenefit;
      }
    }
  }

  return null;
};

export const planHasDrugCoverage = (plan: Plan | SearchResultPlan): boolean =>
  !isMaPlanType(plan.plan_type);

export const getPlanName = (
  plan: BasePlan | PacePlan | ExtendedCoverageInfo | OecStatus,
  language: UserLanguage
): string => {
  let planNameToReturn = "name" in plan ? plan.name : plan.plan_name;

  if ("name" in plan) {
    if (language === UserLanguage.ENGLISH) {
      planNameToReturn = plan.name;
    } else {
      planNameToReturn = plan.name_esp ? plan.name_esp : plan.name;
    }
  } else {
    if (language === UserLanguage.ENGLISH) {
      planNameToReturn = plan.plan_name;
    } else {
      planNameToReturn = plan.plan_name_esp
        ? plan.plan_name_esp
        : plan.plan_name;
    }
  }

  return planNameToReturn || "";
};

export const getPlanTypeForCoverageType = (
  coverageType: CoverageType
): string =>
  coverageType === CoverageType.MAPD ? PlanType.MAPD : PlanType.PDP;

export const isValidLongPlanId = (id?: string): boolean =>
  !id ? false : /\d{4}-[A-Z|0-9]+-\d+-\d+/.test(id);

/** @example "2021/H5652/002/0" */
export const isValidApiLongPlanId = (id?: string): boolean =>
  !id ? false : /\d{4}\/[A-Z|0-9]+\/\d+\/\d+/.test(id);

/**
 * Returns the plan year according to whichever property contains it, or an empty string
 */
export const getPlanYearToUse = (
  plan: OecStatus | SearchResultPlan | ExtendedCoverageInfo
) => {
  let yearToUse = "";
  if ("year" in plan) {
    yearToUse = plan.year;
  } else if ("coverage_year" in plan) {
    yearToUse = plan.coverage_year;
  } else if ("contract_year" in plan && plan.contract_year) {
    yearToUse = plan.contract_year;
  }
  return yearToUse;
};

/**
 * Asserts whether a plan's contract year is GT or LT a given test year
 * @param params
 * @param params.contractYear - A `plan.contract_year` string
 * @param params.testYear - A numeric year value
 * @param [params.test] - Can be set to `"greaterThan"` or `"lessThan"`
 */
export const contractYearTest = ({
  contractYear,
  testYear,
  test,
}: {
  contractYear: string;
  testYear: number;
  test: "greaterThan" | "lessThan";
}): boolean => {
  const contractYearNum = Number(contractYear);
  if (isNaN(contractYearNum)) return false;
  return test === "greaterThan"
    ? contractYearNum > testYear
    : contractYearNum < testYear;
};

export const contractYearLessThan = (contractYear: string, testYear: number) =>
  contractYearTest({ contractYear, testYear, test: "lessThan" });

export const contractYearGreaterThan = (
  contractYear: string,
  testYear: number
) => contractYearTest({ contractYear, testYear, test: "greaterThan" });
