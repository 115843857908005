import {
  PlanType,
  LowIncomeSubsidyStatus,
  UserRole,
  UserLanguage,
  Envs,
} from "../../../@types";
import { Location } from "history";
import {
  AlertVariation,
  AlertWeight,
  AlertRole,
} from "@cmsgov/design-system/dist/types/Alert/Alert";
import { LongPlanId } from "../../../helpers/routing-hooks/types";

export type AnalyticsState = Record<string, unknown>;

export enum AnalyticsActionType {
  SEND_ALERT_IMPRESSION_EVENT = "SEND_ALERT_IMPRESSION_EVENT",
  SEND_BUTTON_ENGAGEMENT_EVENT = "SEND_BUTTON_ENGAGEMENT_EVENT",
  SEND_GA4_EVENT = "SEND_GA4_EVENT",
  /** @deprecated use `sendAnalyticsEvent` with `LinkClickedEvent` instead */
  SEND_INTERNAL_LINK_CLICKED_EVENT = "SEND_INTERNAL_LINK_CLICKED_EVENT",
  SEND_TEALIUM_EVENT = "SEND_TEALIUM_EVENT",
  SEND_TEALIUM_LINK = "SEND_TEALIUM_LINK",
  SEND_TEALIUM_PAGE_VIEW = "SEND_TEALIUM_PAGE_VIEW",
  SEND_PAGE_VIEW = "SEND_PAGE_VIEW",
}

type AlertImpressionEventAction = {
  type: AnalyticsActionType.SEND_ALERT_IMPRESSION_EVENT;
  settings: AlertImpressionProps;
};

type ButtonEngagementEventAction = {
  type: AnalyticsActionType.SEND_BUTTON_ENGAGEMENT_EVENT;
  settings: ButtonEngagementProps;
};

type Ga4EventAction = {
  type: AnalyticsActionType.SEND_GA4_EVENT;
  settings: Ga4EventDimensions;
};

type InternalLinkClickedEventAction = {
  type: AnalyticsActionType.SEND_INTERNAL_LINK_CLICKED_EVENT;
  settings: InternalLinkEventProps;
};

type TealiumEventAction = {
  type: AnalyticsActionType.SEND_TEALIUM_EVENT;
  settings: TealiumEventProps;
};

type TealiumLinkAction = {
  type: AnalyticsActionType.SEND_TEALIUM_LINK;
  settings: Record<string, unknown>;
};

type TealiumPageViewAction = {
  type: AnalyticsActionType.SEND_TEALIUM_PAGE_VIEW;
  settings: TealiumPageViewProps;
};

type PageViewAction = {
  type: AnalyticsActionType.SEND_PAGE_VIEW;
  settings: PageViewProps;
};
export interface Ga4PageViewDimensions {
  [key: string]: string;
  content_language: UserLanguage;
  content_type: string;
  logged_in: "true" | "false";
  open_enrollment: "true" | "false";
  page_name: string;
  role: UserRole;
  site_domain: string;
  site_environment: Envs;
  site_section: string;
}

export interface TealiumPageViewProps {
  location: Location<unknown>;
  mctCurrentPlanYear: number | undefined;
  page_name: string;
  page_type?: string;
}

export interface TealiumEventProps {
  event_action: string;
  event_label: string;
  event_category?: string;
  other_props?: Record<string, unknown>;
}

export interface PageViewProps extends Record<string, unknown> {
  location: Location;
  mctCurrentPlanYear: number | undefined;
  page_name: string;
  page_type?: string;
  custom_page_name?: string;
}

export interface AlertImpressionProps {
  heading: string;
  text: string;
  type: AlertImpressionType;
  link_type?: LinkType;
}
export interface ButtonEngagementProps {
  button: AnalyticsButton;
  custom?: Record<string, string>;
  linkUrl?: string;
}

export interface InternalLinkEventProps {
  custom?: Record<string, string>;
  linkText: string;
  linkUrl: string;
  text?: string;
  parent_component_heading?: string;
  parent_component_type?: Ga4ParentComponentType;
}

export type AnalyticsActions =
  | AlertImpressionEventAction
  | ButtonEngagementEventAction
  | Ga4EventAction
  | InternalLinkClickedEventAction
  | TealiumEventAction
  | TealiumLinkAction
  | TealiumPageViewAction
  | PageViewAction;

export enum Ga4EventType {
  UI_INTERACTION = "ui interaction",
  CONVERSION = "conversion",
  NON_INTERACTION = "non-interaction",
  CONTENT_TOOLS = "content tools",
}

export enum Ga4ParentComponentType {
  Alert = "alert",
  Card = "card",
  NA = "N/A",
  Page = "app page",
  PlanHeader = "plan header",
  Table = "table",
}

/**
 * @deprecated
 * instead of adding to this list, define a new type in `Analytics/eventTypes.ts`
 * In that type, include the other parameters we expect from that `event_name`
 */
export enum Ga4Event {
  ACCORDION_CLOSED = "accordion_closed",
  ACCORDION_OPENED = "accordion_opened",
  ADD_PROVIDER_TO_COMPARE = "mct_provider_added_for_comparison",
  ALERT_IMPRESSION = "alert_impression",
  BADGE_IMPRESSION = "badge_impression",
  BIOSIMILAR_IMPRESSION = "mct_biosimilar_impression",
  BIOSIMILAR_LINK_CLICKED = "mct_biosimilar_link_clicked",
  BUTTON_ENGAGEMENT = "button_engagement",
  COMPARE_PROVIDERS = "mct_providers_compared",
  CONFIRM_PHARMACIES_VIEWED = "mct: confirm pharmacies",
  CONTINUE_IDLE_SESSION = "mct_plan_finder_continue_idle_session",
  COVERAGE_SELECTED = "mct_coverage_type_selected",
  CW_COMPLETED = "mct_coverage_wizard_completed",
  CW_STARTED = "mct_coverage_wizard_started",
  DROPDOWN_SELECTION = "dropdown_selection",
  DRUG_ADDED = "mct_plan_finder_drug_added",
  DRUG_EDITED = "mct_plan_finder_drug_edited",
  DRUG_REMOVED = "mct_plan_finder_drug_removed",
  DRUG_SEARCH_PREF = "mct_plan_finder_drug_search_preferences",
  DRUGS_MANAGED = "mct_plan_finder_drugs_managed",
  ENROLLMENT_COMPLETED = "enrollment_completed",
  ENROLLMENT_STARTED = "enrollment_started",
  FILTER_CLEARED = "filter_cleared",
  FOOTNOTE_IMPRESSION = "footnote_impression",
  HELP_DRAWER_CLOSED = "help_drawer_closed",
  HELP_DRAWER_OPENED = "help_drawer_opened",
  IN_NETWORK_PHARMACY_BACK_TO_PLAN_DETAILS = "mct_back_to_plan_details_clicked",
  IN_NETWORK_PHARMACY_SELECTED = "mct_plan_finder_pharmacy_selected",
  INTERNAL_LINK_CLICKED = "internal_link_clicked",
  JUMP_LINK_CLICKED = "jump_link_clicked",
  LIST_SORTED = "list_sorted",
  LOWEST_COST_PHARMA_ALERT_CLOSED = "mct_low_cost_pharma_alert_closed",
  LOWEST_COST_PHARMA_ALERT_IMPRESSION = "mct_low_cost_pharma_alert_impression",
  MODAL_CLOSED = "modal_closed",
  MODAL_IMPRESSION = "modal_impression",
  MEDIGAP_SEARCH = "mct_medigap_search",
  PACE_PLAN_SEARCH = "mct_paceplan_search",
  PACE_PLANS_CTA_CLICKED = "mct_pace_plans_cta_clicked",
  PHARMACY_MAP_ENGAGEMENT = "mct_plan_finder_map_engagement",
  PHARMACY_REMOVED = "mct_plan_finder_pharmacy_removed",
  PHARMACY_SEARCH_RESULTS = "mct_plan_finder_pharmacy_search_results",
  PHARMACY_SELECTED = "mct_plan_finder_pharmacies_managed",
  PLAN_DETAILS = "mct_plan_finder_plan_details",
  PLAN_DETAILS_COVERAGE_GAP = "plan_details_subtable_header_caret_when_will_you_enter_the_coverage_gap",
  PLAN_DETAILS_HELP_CARET_CLICKED = "mct_plan_details_help_caret_clicked",
  PLAN_DETAILS_NAVIGATION_CLICKED = "mct_plan_details_navigation_tab_clicked",
  PLAN_DETAILS_VIEW_MORE_CLOSED = "mct_plan_details_view_more_closed",
  PLAN_DETAILS_VIEW_MORE_EXPANDED = "mct_plan_details_view_more_expanded",
  PLAN_ENROLL_CLICKED = "mct_plan_finder_plan_enroll_clicked",
  PLAN_FINDER_ADD_RECENT_FILLED_DRUGS = "mct_plan_finder_add_recent_filled_drugs",
  PLAN_FINDER_COMPLETED = "mct_plan_finder_completed",
  PLAN_FINDER_EDIT_DRUG = "mct_plan_finder_edit_drug",
  PLAN_FINDER_PHARMACY_REMOVED = "mct_plan_finder_pharmacy_removed",
  PLAN_FINDER_PHARMACY_SEARCH_RESULTS = "mct_plan_finder_pharmacy_search_results",
  PLAN_FINDER_SAVED_DRUGS_MANAGED = "mct_plan_finder_saved_drugs_managed",
  PLAN_FINDER_SAVED_PHARMACIES_MANAGED = "mct_plan_finder_saved_pharmacies_managed",
  PLAN_FINDER_STARTED = "mct_plan_finder_started",
  PRINT = "print_page",
  RADIO_BUTTON_SELECTED = "radio_button_list_selected",
  RESULTS_FILTERED = "results_filtered",
  SAVED_PHARMACIES_VIEWED = "mct_plan_finder_saved_pharmacies_viewed",
  SHOW_IDLE_SESSION_MODAL = "mct_plan_finder_show_idle_session_modal",
  SYSTEM_END_IDLE_SESSION = "mct_plan_finder_system_end_idle_session",
  TOOLTIP_VIEWED = "tooltip_viewed",
  USER_END_IDLE_SESSION = "mct_plan_finder_user_end_idle_session",
  VIEW_DRUG_COVERAGE = "mct_plan_finder_view_drug_coverage",
  VIEW_DRUG_LIST = "mct_plan_finder_view_drug_list",
  YOY_CTA_ENGAGED = "mct_yoy_cta_engaged",
  PHARMACY_DISTANCE_VIEWED = "mct_plan_finder_pharmacy_distance_viewed",
}

/**
 * @deprecated use `AnalyticsKey` instead.
 *
 * This introduces a second "source of truth" as to what the analytics event keys are.
 * Rather than update both this enum and `Ga4EventDimensions` every time we add a new key, we can derive our
 * values from `Ga4EventDimensions` directly.
 *
 * When we type something as `AnalyticsKey`, we always have the most up-to-date typing, and we don't need to
 * look up the separate enum value. And we still keep our type safety.
 *
 * As of writing this note, we have keys that exist in `Ga4EventDimensions`,
 * but not as a `Ga4EventDimension` member, and vice-versa, which can lead to frustrating typings
 * and references that are not up-to-date.
 */
export enum Ga4EventDimension {
  ALERT_TYPE = "alert_type",
  BENEFICIARY_KEY = "beneficiary_key",
  BUTTON_STYLE = "button_style",
  BUTTON_TYPE = "button_type",
  CLICK_TEXT = "click_text",
  COMPARE_COUNT = "compare_count",
  CONTENT_LANGUAGE = "content_language",
  CONTENT_TYPE = "content_type",
  COVERAGE_TYPE = "coverage_type",
  CSR_ID = "csr_id",
  CUSTOM = "custom",
  DRUG_COUNT = "drug_count",
  DRUG_NAME = "drug_name",
  DRUG_TYPE = "drug_type",
  ERROR_CODE = "error_code",
  ERROR_MESSAGE = "error_message",
  ERROR_TYPE = "error_type",
  EVENT_NAME = "event_name",
  EVENT_TYPE = "event_type",
  EXTENSION = "extension",
  FILTER_ACTION = "filter_action",
  FILTER_NAME = "filter_name",
  FILTER_TYPE = "filter_type",
  FORM_FIELD_LABEL = "form_field_label",
  HEADER = "header",
  HEADING = "heading",
  LINK_TEXT = "link_text",
  LINK_TYPE = "link_type",
  LINK_URL = "link_url",
  LOGGED_IN = "logged_in",
  MCT_ALERT_TOPIC = "mct_alert_topic",
  MCT_BIOSIMILAR_DRUG_NAME = "mct_biosimilar_drug_name",
  MCT_BIOSIMILAR_REFERENCE_DRUG_NAME = "mct_biosimilar_reference_drug_name",
  MCT_COVERAGE_WIZARD = "mct_coverage_wizard",
  MCT_IN_NETWORK_PHARMACY_FINDER = "mct_in_network_pharmacy_finder",
  MCT_INSULIN_DRUG_NAME = "mct_insulin_drug_name",
  MCT_INSURANCE_PROVIDER = "mct_insurance_provider",
  MCT_INSURANCE_TYPE = "mct_insurance_type",
  MCT_LIS = "mct_lis",
  MCT_MEDIGAP = "mct_medigap",
  MCT_PHARMACY_NAME = "mct_pharmacy_name",
  MCT_PHARMACY_TYPE = "mct_pharmacy_type",
  MCT_PLAN_TYPE = "mct_plan_type",
  MCT_PLAN_YEAR = "mct_plan_year",
  MCT_VACCINE_DRUG_NAME = "mct_vaccine_drug_name",
  MCT_VIEWED_DRUGS = "mct_viewed_drugs",
  OPEN_ENROLLMENT = "open_enrollment",
  PAGE_NAME = "page_name",
  PARENT_COMPONENT_HEADING = "parent_component_heading",
  PARENT_COMPONENT_TYPE = "parent_component_type",
  PHARMACY_COST = "pharmacy_cost",
  PHARMACY_COUNT = "pharmacy_count",
  PHARMACY_DISTANCE = "pharmacy_distance",
  PHARMACY_NPI = "pharmacy_npi",
  PLAN_ID = "plan_id",
  RADIO_BUTTON_TEXT = "radio_button_text",
  ROLE = "role",
  SEARCH_RESULT_COUNT = "search_result_count",
  SEARCH_RESULT_TYPE = "search_result_type",
  SEARCH_RESULT_POSITION = "search_result_position",
  SITE_DOMAIN = "site_domain",
  SITE_ENVIRONMENT = "site_environment",
  SITE_SECTION = "site_section",
  STAR_RATING = "star_rating",
  SUB_SECTION = "sub_section",
  TEXT = "text",
  TYPE = "type",
}

export enum MctPlanTypeStrings {
  MEDICARE_ADVANTAGE_PLAN = "medicare advantage plan",
  ORIGINAL_MEDICARE = "original medicare",
  MEDIGAP_PLAN = "PLAN_TYPE_MG",
  PACE_PLAN = "PLAN_TYPE_PACE",
}

export enum MctPlanTypeCoverageSelector {
  MAPD = "PLAN_TYPE_MAPD",
  PDP = "PLAN_TYPE_PDP",
  MedigapPolicy = "PLAN_TYPE_MG",
  learnMore = "PLAN_TYPE_LEARN_MORE",
}

export enum AnalyticsButtonStyle {
  NONE = "", // not any of the following
  DEFAULT = "default",
  PRIMARY = "primary",
  SECONDARY = "secondary",
  SUCCESS = "success",
  TRANSPARENT = "transparent",
}

export enum AnalyticsButtonType {
  SUBMIT = "submit",
  BUTTON = "button",
  RESET = "reset",
  LINK = "link",
}

export enum PlanCompareAnalyticsSubsection {
  NEXT_STEPS = "next steps",
  NEXT_PLAN_YEAR = "next year plan",
}

export enum SearchResultType {
  MAP_CLICK = "map click",
}

export enum Extension {
  DESIGN_SYSTEM_INTEGRATION = "Design system integration",
}

/** @deprecated use the string 'link_other' */
export enum LinkType {
  LINK_OTHER = "link_other",
}
export interface AnalyticsButton {
  text: string;
  buttonStyle: AnalyticsButtonStyle;
  buttonType: AnalyticsButtonType;
}

// "informative" is the default `Alert` variation, which has no type label
export type AlertImpressionType =
  | AlertVariation
  | AlertWeight
  | Extract<AlertRole, "status">
  | "informative";

export type Ga4EventDimensions = {
  alert_type?: AlertImpressionType;
  alert_topic?: string;
  beneficiary_key?: string;
  button_style?: AnalyticsButtonStyle;
  button_type?: AnalyticsButtonType;
  click_text?: string;
  compare_count?: number;
  content_language?: UserLanguage;
  content_type?: string;
  coverage_type?: string;
  csr_id?: string;
  custom?: Record<string, string>;
  drug_count?: number;
  drug_name?: string;
  drug_type?: string;
  error_code?: string;
  error_message?: string;
  event_name: Ga4Event;
  event_type?: string;
  extension?: Extension;
  filter_action?: "applied" | "removed";
  filter_name?: string; // Selected filter value
  filter_type?: string;
  form_field_label?: string;
  header?: string;
  heading?: string;
  insurance_carrier?: string;
  link_text?: string;
  link_type?: LinkType;
  link_url?: string;
  logged_in?: boolean;
  mct_alert_topic?: string;
  mct_biosimilar_drug_name?: string;
  mct_biosimilar_reference_drug_name?: string;
  mct_coverage_wizard?: boolean;
  mct_in_network_pharmacy_finder?: "yes" | "no";
  mct_insulin_drug_name?: string;
  mct_insurance_provider?: string;
  mct_insurance_type?: PlanType;
  mct_lis?: LowIncomeSubsidyStatus;
  mct_medigap?: boolean;
  mct_pharmacy_name?: string;
  mct_pharmacy_type?: string;
  mct_plan_type?: PlanType | MctPlanTypeStrings | MctPlanTypeCoverageSelector;
  mct_plan_year?: number;
  mct_vaccine_drug_name?: string;
  mct_viewed_drugs?: boolean;
  open_enrollment?: boolean;
  page_name?: string;
  parent_component_heading?: string;
  parent_component_type?: Ga4ParentComponentType;
  plan_costs?: string;
  plan_id?: LongPlanId;
  plan_name?: string;
  plan_type?: PlanType;
  pharmacy_cost?: string;
  pharmacy_count?: number;
  pharmacy_distance?: string;
  pharmacy_npi?: string;
  radio_button_text?: string;
  role?: UserRole;
  search_result_count?: number;
  search_result_type?: SearchResultType;
  search_result_position?: number;
  site_domain?: string;
  site_environment?: string;
  site_section?: string;
  star_rating?: number;
  sub_section?: string;
  text?: string;
  type?: string;
};

export type AnalyticsKey = keyof Ga4EventDimensions;
