import {
  AnalyticsEvent,
  ButtonEngagementEvent,
  HelpDrawerOpenEvent,
  MapEngagementEvent,
  PartialWithExceptions,
} from "./eventTypes";

/** This file is explicitly for functions that send analytics events. */

/** primary function for sending an analytics event
 * the AnalyticsEvent type should ensure that we are only sending valid parameters as defined in our `eventTypes` file.
 */
export function sendAnalyticsEvent(event: AnalyticsEvent) {
  if (!window.utag?.link) {
    return;
  }
  window.utag.link(event);
}

export function sendMapEngagementEvent(
  type: MapEngagementEvent["map_engagement_type"],
  text?: string
) {
  sendAnalyticsEvent({
    event_name: "map_engagement",
    map_engagement_type: type,
    link_type: "link_other",
    text,
  });
}

export function sendButtonEngagementEvent({
  text,
  button_style,
  button_type,
  link_url,
  parent_component_heading,
  parent_component_type,
}: PartialWithExceptions<ButtonEngagementEvent, "text">) {
  sendAnalyticsEvent({
    event_name: "button_engagement",
    link_type: "link_other",
    button_style: button_style || "default",
    button_type: button_type || "button",
    link_url: link_url || "",
    parent_component_heading: parent_component_heading || "",
    parent_component_type: parent_component_type || "N/A",
    text,
  });
}

/**
 * Sends help drawer open analytics event.
 * @param heading the help drawer title
 * @param text the toggle text
 */
export function sendHelpDrawerOpenAnalyticsEvent({
  heading,
  text,
}: PartialWithExceptions<HelpDrawerOpenEvent, "heading" | "text">): void {
  sendAnalyticsEvent({
    event_name: "help_drawer_opened",
    heading,
    text,
    link_type: "link_other",
  });
}

export function sendInlineErrorEvent({
  fieldName,
  errorMessage,
}: {
  fieldName?: string;
  /** The text as the user sees it in the header of the error, but EXCLUDE PII like zip code */
  errorMessage?: string;
}) {
  if (errorMessage) {
    sendAnalyticsEvent({
      event_name: "inline_error",
      form_field_label: fieldName || "",
      error_message: errorMessage,
      error_type: "validation",
      link_type: "link_other",
    });
  }
}
