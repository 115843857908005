import {
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@cmsgov/ds-medicare-gov";
import React, { useState } from "react";
import {
  $$,
  normalizeNpi,
  uppercaseFirst,
  useAppContext,
  useFormatMessage,
} from "../../helpers";
import { M3PMonthlyCosts, M3pData } from "../../@types";
import { hasMailOrderPharmacy } from "../../app/store";
import { SelectWithButton } from "..";
import { MedicareGovAnchor } from "../MedicareGovAnchor";
import { FormattedMessage } from "react-intl";
import { TranslationKey, useTypedTranslate } from "../../helpers/intlHooks";
import { M3PModalPlanHeading } from "./M3PModalPlanHeading";
import {
  getPlanStackedHeadings,
  getTranslatedMonthNameFromM3pDate,
} from "./m3pModalHelpers";
import { getLocaleFromUserLang } from "../../helpers/dateHelpers";
import { addParentComponentProps } from "../../app/contexts/Analytics/helpers";

export interface M3PModalProps {
  onExit: () => void;
  m3pData: M3pData;
}

export interface MP3MonthlyCostsWithMonthNumber extends M3PMonthlyCosts {
  month: number;
}

export const m3pModalHeadingTranslationKey: TranslationKey =
  "m3p.modal.heading";

const m3pModalId = "m3p-modal";

export const M3PModal = ({ onExit = () => {}, m3pData }: M3PModalProps) => {
  // * Context
  const {
    state: { pharmacies, pharmacyType, language },
  } = useAppContext();

  // * Translation
  const t = useTypedTranslate();
  const fm = useFormatMessage();

  // Options for select
  let pharmacyOptions: { label: string; value: string }[] = pharmacies.reduce(
    (opts, p) => {
      const npi = normalizeNpi(p.npi);
      return m3pData && m3pData[npi]
        ? [
            ...opts,
            {
              label: p.name,
              value: npi,
            },
          ]
        : opts;
    },
    [] as { label: string; value: string }[]
  );
  const hasMailOrder = hasMailOrderPharmacy({ pharmacyType });
  const mailOrderNpi = normalizeNpi("");
  if (hasMailOrder && m3pData && m3pData[mailOrderNpi]) {
    pharmacyOptions = pharmacyOptions.concat({
      label: t("plan_details.pharmacies.mail_order_pharmacy"),
      value: mailOrderNpi,
    });
  }

  // * State
  const [npi, setNpi] = useState<string | undefined>(pharmacyOptions[0]?.value);

  // Nothing further to do if there are no pharmacies with m3pData
  // (No early return before all hooks are called)
  if (!npi || !m3pData[npi]) {
    return null;
  }

  const {
    estimated_monthly_costs,
    estimated_yearly_mppp_cost,
    estimated_yearly_part_d_only_cost,
  } = m3pData[npi];

  const locale = getLocaleFromUserLang(language);
  const monthHeading = t("m3p.modal.table.heading.month");
  const modalHeading = t(m3pModalHeadingTranslationKey);
  const stackedHeadings = getPlanStackedHeadings(fm);

  return (
    <Dialog
      aria-label={modalHeading}
      id={m3pModalId}
      onEnter={() => {
        // Timeout seems necessary for this to work in Safari
        setTimeout(() => {
          // Modal automatically focuses on and scrolls down to close button
          // location (I think). This shifts scrollTop back to 0.
          const modalEl = document.getElementById(m3pModalId);
          if (modalEl) {
            modalEl.scrollTop = 0;
          }
        }, 0);
      }}
      onExit={onExit}
      heading={modalHeading}
      className="ds-c-dialog--full mct-c-modal-m3p"
      headerClassName="mct-c-modal-m3p__header"
    >
      <p className="ds-u-margin-y--0">{t("m3p.modal.description")}</p>
      <p className="ds-u-margin-y--1">
        <FormattedMessage
          id={"m3p.modal.description.line1"}
          values={{
            b: chunks => <strong>{chunks}</strong>,
          }}
        />
      </p>
      <p className="ds-u-margin-y--1">{t("m3p.modal.description.line2")}</p>
      <p className="ds-u-margin-y--1">
        <MedicareGovAnchor
          urlKey="prescriptionPaymentPlan"
          {...addParentComponentProps(
            t(m3pModalHeadingTranslationKey),
            "modal"
          )}
        >
          {t("m3p.modal.medicare_link_text")}
        </MedicareGovAnchor>
      </p>
      <div className="mct-c-modal-m3p__select-table-container">
        <SelectWithButton
          buttonClassName="mct-c-modal-m3p__change-button"
          label={t("m3p.modal.select.label")}
          name="Pharmacy switch"
          options={pharmacyOptions}
          hint={t("m3p.modal.select.hint")}
          onSelectApplied={value => {
            setNpi(`${value}`);
          }}
        >
          {uppercaseFirst(t("change"))}
        </SelectWithButton>
        <Table
          className="mct-c-modal-m3p__cost-breakdown"
          stackable={true}
          stackableBreakpoint="sm"
        >
          <TableHead>
            <TableRow>
              <TableCell rowSpan={2} id="m3p-month">
                {monthHeading}
              </TableCell>
              <TableCell colSpan={2} id="m3p-monthly-cost">
                {t("m3p.modal.table.heading.your_monthly_cost")}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell id="m3p-cost-without">
                <M3PModalPlanHeading withPlan={false} />
              </TableCell>
              <TableCell id="m3p-cost-with">
                <M3PModalPlanHeading />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {estimated_monthly_costs.map(row => {
              const monthName = getTranslatedMonthNameFromM3pDate({
                date: row.date,
                locale,
              });
              return (
                <TableRow key={row.date}>
                  <TableCell
                    headers="m3p-month"
                    stackedTitle={monthHeading}
                    className="mct-c-modal-m3p__cost-breakdown-stacked-header"
                  >
                    {monthName}
                  </TableCell>
                  <TableCell
                    headers="m3p-cost-without"
                    stackedTitle={stackedHeadings.withoutPlan}
                  >
                    {$$(row.part_d_only_total)}
                  </TableCell>
                  <TableCell
                    headers="m3p-cost-with"
                    stackedTitle={stackedHeadings.withPlan}
                  >
                    {$$(row.mppp_total)}
                  </TableCell>
                </TableRow>
              );
            })}
            <TableRow>
              <TableCell className="ds-u-font-weight--bold">
                {t("m3p.modal.table.row.heading.total").toUpperCase()}
              </TableCell>
              <TableCell
                className="ds-u-font-weight--bold"
                headers="m3p-cost-without"
                stackedTitle={stackedHeadings.withoutPlan}
              >
                {$$(estimated_yearly_part_d_only_cost)}
              </TableCell>
              <TableCell
                className="ds-u-font-weight--bold"
                headers="m3p-cost-with"
                stackedTitle={stackedHeadings.withPlan}
              >
                {$$(estimated_yearly_mppp_cost)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>

      <FormattedMessage
        id="m3p.modal.content.payment_limits"
        values={{
          p: chunks => <p>{chunks}</p>,
          ul: chunks => <ul>{chunks}</ul>,
          li: chunks => <li>{chunks}</li>,
        }}
      />

      <p>{t("m3p.modal.content.premium_note")}</p>
    </Dialog>
  );
};
