export enum QueryKey {
  Counties = "geography/counties",
  DrugCosts = "drugs/cost",
  MapboxForward = "mapbox/forward",
  MedigapCoverages = "other-insurance",
  MedigapPlanPremiumRanges = "medigap/plans",
  MedigapPolicies = "medigap/policies",
  PACEStates = "plans/pace/states",
  PAPPlans = "pap/drug-programs",
  PendingEnrollments = "oec-status",
  Plan = "plan",
  SPAPPlans = "pap/state-programs",
  SPAPStates = "pap/states",
  StatusSummary = "status-summary",
}

export enum StaleTime {
  /** default stale time */
  Zero = 0,
  /**
   * 5 second buffer to avoid duplicate calls if, for example,
   * two components render one after another, both of which need query data
   * TODO: Have discussion about this value with team. This is an initial stab.
   * */
  AvoidDuplicateQueries = 5 * 1000,
  /** one hour stale time for data that updates very infrequently */
  InfrequentUpdates = 60 * 60 * 1000,
  /** never stale, data that we expect to be unchanging */
  Infinite = Infinity,
}
