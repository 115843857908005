import { useLocation } from "react-router";
import URI from "urijs";
import { parseSearchParams } from "../objectUtilities";
import { UserLanguage } from "../../@types";
import { useCurrentPlanYear } from "../yearFlagHelpers";

type UseSearchParamsObject = {
  selectedYear: number;
  selectedLanguage: UserLanguage;
};
type UseSearchParamsReturn<B extends boolean> = B extends true
  ? UseSearchParamsObject
  : Partial<UseSearchParamsObject>;

/**
 *  This hook checks for an existing `lang` and `year` search param.
 *
 * @param includeFallback If passed `true`, it will return a type-safe fallback, in the event where it fails to find the param.
 * @example const { selectedYear, selectedLang } = useSearchParams(); // properties may be undefined
 * @example const { selectedYear, selectedLang } = useSearchParams(true); // properties will be defined
 */
export const useSearchParams = <B extends boolean>(
  /** if `true` this will fallback to default year and language responses, instead of undefined */
  includeFallback?: B
): UseSearchParamsReturn<B> => {
  // * Routing
  const { search } = useLocation();
  const { year, lang } = parseSearchParams(URI.parseQuery(search));

  // * Flags
  const currentPlanYear = useCurrentPlanYear();
  const validYears = [currentPlanYear, currentPlanYear + 1];

  // * Constants
  const selectedYear = validYears.some(x => x === Number(year))
    ? Number(year)
    : undefined;
  const langAbbr = lang && lang.substring(0, 2); // returns 'es' from 'es-mx', for example
  const selectedLanguage = Object.values(UserLanguage).some(x => x === langAbbr)
    ? (langAbbr as UserLanguage) // 'en' or 'es'
    : undefined;

  if (includeFallback) {
    return {
      selectedYear: selectedYear || currentPlanYear,
      selectedLanguage: selectedLanguage || UserLanguage.ENGLISH,
    };
  }
  return {
    selectedLanguage,
    selectedYear,
  } as UseSearchParamsReturn<B>;
};
