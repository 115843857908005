import { SvgIcon } from "@cmsgov/ds-medicare-gov";
import { IconCommonProps } from "@cmsgov/design-system/dist/types/Icons/SvgIcon";
import React, {
  FC,
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useRef,
} from "react";

const defaultProps = {
  className: "",
  title: "home",
  viewBox: "0 0 17 15",
};

interface HomeLocationIconProps extends IconCommonProps {
  /**
   * Indicates whether icon is being used for home location on pharmacy map, wraps
   * the SVG in a `div` to attach a `ref`. Not needed in other scenarios
   */
  homeLocationIcon?: boolean;
}

const WrapperEl: FC<{
  /**
   * Indicates whether icon is being used for home location on pharmacy map, wraps
   * the SVG in a `div` to attach a `ref`. Not needed in other scenarios
   */
  homeLocationIcon?: boolean;
  wrapperRef: React.MutableRefObject<HTMLDivElement | null>;
}> = ({ children, homeLocationIcon, wrapperRef }) =>
  homeLocationIcon ? <div ref={wrapperRef}>{children}</div> : <>{children}</>;

const HomeIconComponent: ForwardRefRenderFunction<
  SVGSVGElement,
  HomeLocationIconProps
> = (props, iconRef): React.ReactElement => {
  const iconCssClasses = `mct-c-icon--home ${props.className || ""}`;
  const innerRef = useRef<HTMLDivElement | null>(null);
  useImperativeHandle(
    iconRef,
    () => innerRef.current?.firstElementChild as SVGSVGElement
  );
  const { homeLocationIcon, ...otherProps } = props;

  return (
    <WrapperEl wrapperRef={innerRef} homeLocationIcon={homeLocationIcon}>
      <SvgIcon {...defaultProps} {...otherProps} className={iconCssClasses}>
        <path d="M16.5130047,6.8360992 L14.81483,5.3364162 L14.81483,1.38828298 C14.81483,1.13260031 14.6075583,0.925328557 14.3518756,0.925328557 L12.5000579,0.925328557 C12.2443752,0.925328557 12.0371035,1.13260031 12.0371035,1.38828298 L12.0371035,2.88391513 L9.10746999,0.297735963 C8.66837593,-0.099245321 8,-0.099245321 7.56091286,0.297735963 L0.153642034,6.83696724 C0.0622596073,6.91902561 0.00722900155,7.03403295 0.000662738228,7.15667555 C-0.0059035251,7.27931816 0.036532745,7.39954386 0.118631106,7.49089036 L0.738121998,8.17924573 C0.820180368,8.27062815 0.935187712,8.32565876 1.05783032,8.33222502 C1.18047292,8.33879128 1.30069862,8.29635501 1.39204512,8.21425665 L8.02733944,2.36135532 C8.20233808,2.2069693 8.46488737,2.2069693 8.63988602,2.36135532 L15.2751803,8.21396731 C15.3665268,8.29606567 15.4867525,8.33850194 15.6093951,8.33193568 C15.7320377,8.32536941 15.8470451,8.27033881 15.9291035,8.17895638 L16.548305,7.48973298 C16.7190012,7.29947082 16.703199,7.00687103 16.5130047,6.8360992 Z M8.33346806,3.32690464 L2.01037863,8.90290136 C1.96350449,8.94514595 1.90360977,8.96482152 1.85210609,8.99983245 L1.85210609,14.3510069 C1.85210609,14.6066896 2.05937784,14.8139613 2.31506051,14.8139613 L6.01869592,14.8139613 C6.27437859,14.8139613 6.48165035,14.6066896 6.48165035,14.3510069 L6.48165035,10.6473715 C6.48165035,10.3916888 6.68892211,10.1844171 6.94460478,10.1844171 L9.72233133,10.1844171 C9.978014,10.1844171 10.1852858,10.3916888 10.1852858,10.6473715 L10.1852858,14.3510069 C10.1852858,14.6066896 10.3925575,14.8139613 10.6482402,14.8139613 L14.3518756,14.8139613 C14.6075583,14.8139613 14.81483,14.6066896 14.81483,14.3510069 L14.81483,9.00098983 C14.7656411,8.96742564 14.7071931,8.94832877 14.6626338,8.90839895 L8.33346806,3.32690464 Z" />
      </SvgIcon>
    </WrapperEl>
  );
};

export const HomeIcon = forwardRef(HomeIconComponent);
