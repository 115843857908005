import React, { useCallback, useContext } from "react";
import URI from "urijs";
import {
  getToastRoute,
  parseLangFromRelayParam,
  parseSearchParams,
  sendToFromIfExists,
  useAppContext,
} from ".";
import {
  PrescriptionDrug,
  CoverageInfo,
  BeneficiaryAndApiHeaders,
  Beneficiary,
  IdParts,
  UserLanguage,
  AppState,
  Action,
  ActionType,
  MctToastType,
  PDE,
  ThirdPartyIntegrations,
} from "../@types";
import {
  getCSRBeneInfo,
  getBeneDrugs,
  getBenePharmacies,
  getCounty,
  getCounties,
} from "../api";
import { ApiError, logError } from "./errors";
import { drugsAreDuplicates, uppercaseFirst } from "./prescriptionHelpers";
import { parseDegradedIntegrations } from "./loginHelpers";
import { fetchCurrentPlan, fetchPlanIdsForNextYear } from "./planHelpers";
import { RouteComponentProps, useHistory, useLocation } from "react-router";
import routes from "../app/routes";
import { getYearPartInfo } from "./yearFlagHelpers";
import { getPharmacyType } from "./pharmacyHelpers";
import { AppContext } from "../app/store";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useSearchParams } from "./routing-hooks/useSearchParams";

export async function getBeneInfoForCSR(
  code: string
): Promise<BeneficiaryAndApiHeaders> {
  return await getCSRBeneInfo(code);
}

export function getSLSCode(search: string): string {
  const { code } = parseSearchParams(URI.parseQuery(search));
  return code as string;
}

export const doesNotInclude = (
  acc: PrescriptionDrug[],
  { ndc, name }: { ndc: string; name: string }
): boolean => {
  return acc.every(
    ({ ndc: currentNDC, name: currentName }) =>
      currentNDC !== ndc && currentName !== name
  );
};

interface PlanIdParts extends IdParts {
  contract_year: string;
}

// todo: rename this to arePlansTheSame
export const isCurrentPlan = (
  coverageInfo: CoverageInfo | undefined,
  plan: PlanIdParts
): boolean => {
  if (!coverageInfo) return false;

  return (
    coverageInfo.plan_id === plan.plan_id &&
    coverageInfo.segment_id === plan.segment_id &&
    coverageInfo.contract_id === plan.contract_id &&
    (coverageInfo.contract_year === plan.contract_year ||
      coverageInfo.coverage_year === plan.contract_year)
  );
};

export const deduplicatePrescriptions = (
  prescriptions: PrescriptionDrug[]
): PrescriptionDrug[] => {
  return prescriptions.filter(
    (a, index) =>
      prescriptions.findIndex(b => drugsAreDuplicates(a, b)) === index
  );
};

export const getBeneFirstAndLastName = (
  beneficiary: Beneficiary,
  t: (key: string) => string
): string => {
  const firstName =
    beneficiary.firstname && beneficiary.firstname.length
      ? uppercaseFirst(beneficiary.firstname[0])
      : "";
  return firstName || beneficiary.lastname
    ? `${firstName} ${uppercaseFirst(beneficiary.lastname)}`.trim()
    : t("bene_landing.name_not_available");
};

export const getBeneFirstNameAndLastInitial = (
  beneficiary: Beneficiary,
  t: (key: string) => string
): string => {
  const firstName =
    beneficiary.firstname && beneficiary.firstname.length
      ? uppercaseFirst(beneficiary.firstname[0])
      : "";
  if (firstName && beneficiary.lastname) {
    return `${firstName} ${beneficiary.lastname[0].toUpperCase()}.`.trim();
  } else if (beneficiary.lastname) {
    return uppercaseFirst(beneficiary.lastname).trim();
  } else if (firstName) {
    return firstName;
  } else {
    return t("bene_landing.name_not_available");
  }
};

/**
 * Set or update beneficiary, LIS, and future LIS in app state (store)
 */
export const updateBeneInfo = ({
  beneInfo,
  dispatch,
}: {
  beneInfo?: Beneficiary;
  dispatch: React.Dispatch<Action>;
}) => {
  const fips = beneInfo?.mailing_address.fips_state_county;

  dispatch({
    type: ActionType.ADD_BENEFICIARY,
    payload: beneInfo,
  });

  dispatch({
    type: ActionType.UPDATE_LIS,
    payload: beneInfo?.lis_level,
  });

  dispatch({
    type: ActionType.UPDATE_FUTURE_LIS,
    payload: beneInfo?.future_lis_level,
  });

  // By default, use bene's mailing address to set root-level `fips` and `zipcode`
  // in `AppState`. Keeps LI bene from being prompted for these in various user-flows
  if (beneInfo?.mailing_address.zipcode) {
    dispatch({
      type: ActionType.UPDATE_ZIPCODE,
      payload: beneInfo.mailing_address.zipcode,
    });

    if (fips) {
      getCounty(fips).then(county => {
        dispatch({
          type: ActionType.UPDATE_FIPS,
          payload: fips,
        });

        dispatch({ type: ActionType.UPDATE_COUNTY, payload: county });
      });
    } else {
      // fetch fips, set it if only one
      // TODO: If possible, we should make use of the `useCounties` hook instead of calling the endpoint directly here.
      // TODO: https://jira.cms.gov/browse/MCT-9683
      getCounties(beneInfo.mailing_address.zipcode).then(counties => {
        if (counties.length == 1) {
          dispatch({
            type: ActionType.UPDATE_FIPS,
            payload: counties[0].fips,
          });
          dispatch({
            type: ActionType.UPDATE_COUNTY,
            payload: counties[0],
          });
        }
      });
    }
  }
};

export const useUpdateBeneInfo = () => {
  const { dispatch } = useContext(AppContext);

  const updateBene = useCallback(
    (beneInfo?: Beneficiary) => {
      updateBeneInfo({
        beneInfo,
        dispatch,
      });
    },
    [dispatch]
  );
  return updateBene;
};

export const beneKeysMatch = (bene: Beneficiary, bene2: Beneficiary) =>
  bene.meta_data.beneficiary_key === bene2.meta_data.beneficiary_key;

export interface BeneLoginAndUpdateParams {
  beneInfoWithHeaders: BeneficiaryAndApiHeaders | undefined;
  dispatch: React.Dispatch<Action>;
  hasStateBene: boolean;
  history: RouteComponentProps["history"];
  isLandingPage: boolean;
  isLoginCallbackRoute: boolean;
  isOpenEnrollmentNextYearOnly: boolean | undefined;
  isOutsideOpenEnrollment: boolean | undefined;
  mbpHandoffFailed: AppState["mbpHandoffFailed"];
  needsUserRefresh: boolean;
  pharmacies: AppState["pharmacies"];
  /**
   * The `relay` query param is provided on the sls-callback route.
   * If available, it is used to update user language
   */
  relay?: string;
  routeLang: UserLanguage;
}

/**
 * To be called on login or on page load fo authenticated users if the store may
 * need to be refreshed.
 *
 * `beneInfoWithHeaders` param indicates user already has global session
 */
export const beneLoginAndUpdateHandler = async ({
  dispatch,
  hasStateBene,
  history,
  isLandingPage,
  isLoginCallbackRoute,
  isOpenEnrollmentNextYearOnly,
  isOutsideOpenEnrollment,
  beneInfoWithHeaders,
  mbpHandoffFailed,
  needsUserRefresh,
  pharmacies,
  relay,
  routeLang,
}: BeneLoginAndUpdateParams): Promise<void> => {
  if (!beneInfoWithHeaders) {
    return;
  }
  const userLang = parseLangFromRelayParam(relay) || routeLang;
  const handlerName = "GlobalSessionHandler";
  const { beneficiary: fetchedBene } = beneInfoWithHeaders;
  const hasFetchedBene = !!fetchedBene;

  const authLandingRoute = routes.summary.landingPage;

  dispatch({ type: ActionType.UPDATE_LANGUAGE, payload: userLang });

  if (isLoginCallbackRoute || needsUserRefresh) {
    // Clear out any previous bene info
    dispatch({
      type: ActionType.RESET_STATE,
    });

    // Persist MBP handoff failure status, after the rest of state is reset
    if (mbpHandoffFailed) {
      dispatch({
        type: ActionType.UPDATE_MBP_HANDOFF_FAILED,
        payload: true,
      });
    }

    try {
      const { beneficiary: beneInfo, headers } = beneInfoWithHeaders;

      const degradedIntegrations = parseDegradedIntegrations(headers);

      if (degradedIntegrations.length) {
        dispatch({
          type: ActionType.UPDATE_DEGRADED_INTEGRATIONS,
          payload: degradedIntegrations,
        });
      }

      updateBeneInfo({
        beneInfo,
        dispatch,
      });

      /** Drugs */
      try {
        const drugs = await getBeneDrugs();
        dispatch({
          type: ActionType.SET_PRESCRIPTIONS,
          payload: drugs,
        });
      } catch (e) {
        logError(
          `Failed to get beneficiary drugs (${handlerName})`,
          e as ApiError
        );
      }

      /** Pharmacies */
      try {
        const { pharmacies, mail_order } = await getBenePharmacies();
        dispatch({
          type: ActionType.SET_PHARMACIES,
          payload: pharmacies,
        });

        dispatch({
          type: ActionType.UPDATE_PHARMACY_TYPE,
          payload: getPharmacyType(pharmacies, mail_order),
        });
      } catch (e) {
        logError(
          `Failed to get beneficiary pharmacies (${handlerName})`,
          e as ApiError
        );
      }

      /** Get current plan */
      if (beneInfo?.coverage_current.length) {
        let nextYearPlanIds;
        if (!isOutsideOpenEnrollment || !!isOpenEnrollmentNextYearOnly) {
          nextYearPlanIds = await fetchPlanIdsForNextYear(
            beneInfo.coverage_current[0],
            handlerName
          );
        }
        await fetchCurrentPlan(
          beneInfo,
          pharmacies,
          beneInfo.lis_level,
          dispatch,
          nextYearPlanIds,
          beneInfo.future_lis_level
        );
      }

      // @TODO - figure out which other logic, if any, needs to run only on login callback route
      // or on any route other than login callback
      // `isLoginCallbackRoute` added here, since other routes should return to landing
      if (needsUserRefresh) {
        if (isLandingPage) {
          console.debug(
            `Returning from beneLoginAndUpdateHandler on navigation to main landing page, needsUserRefresh, bene has been updated in state`
          );
          return history.replace(`/?lang=${userLang}`);
        } else {
          console.debug(
            `Returning from beneLoginAndUpdateHandler, bene has been updated in state, staying on the same page`
          );
          return;
        }
      } else {
        console.debug(
          `Returning from beneLoginAndUpdateHandler, bene has been updated in state, routing to bene landing`
        );
        return history.replace(`${authLandingRoute}?lang=${userLang}`);
      }
    } catch (e) {
      logError(
        `Failed to retrieve beneficiary (${handlerName})`,
        e as ApiError
      );

      // @TODO - If we've gotten here, there was a "sid" but no beneficiary in app state,
      // so why are we trying to send the user to `history.location.state.from`?
      // Also, does this only make sense on login callback?
      if (needsUserRefresh) {
        return sendToFromIfExists(history);
      } else {
        // @TODO - Should this re-throw to allow the GlobalSessionHandler to actively
        // logout?
        return history.replace(
          getToastRoute(
            `${routes.questionRouting}?lang=${userLang}`,
            isLoginCallbackRoute
              ? MctToastType.BENE_FAILED_LOGIN
              : MctToastType.SESSION_EXPIRED
          )
        );
      }
    }
  } else if (hasStateBene && hasFetchedBene) {
    console.debug(
      `Returning from beneLoginAndUpdateHandler, bene already in app state`
    );
    return history.replace(`${authLandingRoute}?lang=${userLang}`);
  } else {
    console.debug(
      `Returning from beneLoginAndUpdateHandler, failed to retrieve or update bene info, or failed login`
    );
    // @TODO - Should this re-throw to allow the GlobalSessionHandler to actively
    // logout?
    return history.replace(
      getToastRoute(
        `${routes.questionRouting}?lang=${userLang}`,
        isLoginCallbackRoute
          ? MctToastType.BENE_FAILED_LOGIN
          : MctToastType.SESSION_EXPIRED
      )
    );
  }
};

/**
 * Hook provides all necessary values to `beneLoginAndUpdateHandler`, which is
 * returned memoized for all its dependencies
 */
export const useBeneLoginAndUpdateHandler = (
  beneInfoWithHeaders: BeneficiaryAndApiHeaders | undefined
) => {
  const {
    state: { beneficiary: stateBene, mbpHandoffFailed, pharmacies },
    dispatch,
  } = useAppContext();
  const location = useLocation();
  const history = useHistory();
  const flags = useFlags();
  const { isOutsideOpenEnrollment, isOpenEnrollmentNextYearOnly } =
    getYearPartInfo(flags);
  const { relay } = parseSearchParams(URI.parseQuery(location.search));
  const isLoginCallbackRoute = location.pathname === routes.slsCallback;
  const isLandingPage = location.pathname === "/";
  const fetchedBene = beneInfoWithHeaders?.beneficiary;
  const hasFetchedBene = !!fetchedBene;
  const hasStateBene = !!stateBene;
  /**
   * When `beneLoginAndUpdateHandler` is called, if beneinfo was fetched but it
   * hasn't been added to app state, or if there's both cached and fetched bene
   * info, but their keys don't match, the store will need to be refreshed (this
   * value may be referenced after the store has been updated)
   */
  const needsUserRefresh =
    (hasFetchedBene && !hasStateBene) ||
    (hasFetchedBene && hasStateBene && !beneKeysMatch(stateBene, fetchedBene));
  const runOnce =
    beneInfoWithHeaders && (needsUserRefresh || isLoginCallbackRoute);

  const { selectedLanguage: routeLang } = useSearchParams(true);
  const beneHandler = useCallback(
    () =>
      beneLoginAndUpdateHandler({
        beneInfoWithHeaders,
        dispatch,
        hasStateBene,
        history,
        isLandingPage,
        isLoginCallbackRoute,
        isOpenEnrollmentNextYearOnly,
        isOutsideOpenEnrollment,
        mbpHandoffFailed,
        needsUserRefresh,
        pharmacies,
        relay,
        routeLang,
      }),
    [
      beneInfoWithHeaders,
      dispatch,
      hasStateBene,
      history,
      isLandingPage,
      isLoginCallbackRoute,
      isOpenEnrollmentNextYearOnly,
      isOutsideOpenEnrollment,
      mbpHandoffFailed,
      needsUserRefresh,
      pharmacies,
      relay,
      routeLang,
    ]
  );
  return {
    beneLoginAndUpdateHandler: beneInfoWithHeaders
      ? beneHandler
      : () => Promise.resolve(),
    runOnce,
  };
};

/**
 * Calculates from midnight-to-midnight
 */
export const isOverThirtyDaysSinceLogin = (
  beneficiary: Beneficiary
): boolean => {
  const {
    meta_data: { last_login_date },
  } = beneficiary;
  if (null === last_login_date || !last_login_date) {
    return true;
  }
  const today = new Date(new Date().setHours(0, 0, 0, 0)).getTime();
  const lastLoginDate = new Date(last_login_date).getTime();
  const daysSinceLastLogin = (today - lastLoginDate) / (1000 * 60 * 60 * 24);
  return daysSinceLastLogin > 30;
};

export const getPDEInfo = (
  prescriptions: PrescriptionDrug[],
  pdes?: PDE[]
): { savedPdes: PrescriptionDrug[]; unsavedPdes: PrescriptionDrug[] } => {
  const savedPdes: PrescriptionDrug[] = [];
  const unsavedPdes: PrescriptionDrug[] = [];

  if (pdes) {
    pdes.forEach(p => {
      if (!prescriptions.find(d => d.ndc === p.drug.ndc)) {
        unsavedPdes.push(p.drug);
      } else {
        savedPdes.push(p.drug);
      }
    });
  }

  return { savedPdes, unsavedPdes };
};

/**
 * Checks values passed in api response headers to determine whether BEDAP is or
 * is not healthy
 */
export const getIsBEDAPDown = (degradedIntegrations: string[]) =>
  degradedIntegrations.includes(ThirdPartyIntegrations.BEDAP);

export const useIsBEDAPDown = () => {
  const {
    state: { degradedIntegrations },
  } = useContext(AppContext);
  return getIsBEDAPDown(degradedIntegrations);
};

export const getBeneMailingAddress = (
  beneficiary: Beneficiary
): string | null => {
  const mailingAddr = beneficiary.mailing_address;
  if (mailingAddr) {
    if (
      !!mailingAddr.address_1 &&
      !!mailingAddr.city &&
      !!mailingAddr.state &&
      !!mailingAddr.zipcode
    ) {
      return `${mailingAddr.address_1}, ${mailingAddr.city}, ${mailingAddr.state}, ${mailingAddr.zipcode}`;
    }
  }
  return null;
};

export const useBeneMailingAddress = (): string | null => {
  const { state } = useAppContext();
  return state.beneficiary ? getBeneMailingAddress(state.beneficiary) : null;
};
